import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  ReactNode,
  ReactElement,
} from 'react';
import { useLocation } from 'react-router-dom';
import transactionContext from '@/contexts/transactionContext';
import useViewport from '@/hooks/useViewport';
import usePaymentProfiles from '@/hooks/usePaymentProfiles';
import useTransactionApi from '@/hooks/useTransactionApi';
import getApiLimit from '@/utils/getTransactionApiLimit';
import paths from '@/router/paths';
import { GetTransactions, Transaction } from '@/types/transactions';
import isEmpty from '@/lib/isEmpty';

const initialPage = 0;
const pollingLimit = 10;

function TransactionProvider({ children }: { children: ReactNode }): ReactElement {
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const currentPage = useRef(initialPage);

  const { getTransactions, isLoading } = useTransactionApi();
  const location = useLocation();
  const { viewportHeight } = useViewport();
  const { selectedPaymentProfileId, selectedPaymentProfileTodayTransactionsId } =
    usePaymentProfiles() || {};

  const currentPaymentProfileId = useMemo(
    () =>
      (location.pathname === paths.newTransactions
        ? selectedPaymentProfileTodayTransactionsId
        : selectedPaymentProfileId) || null,
    [location.pathname, selectedPaymentProfileId, selectedPaymentProfileTodayTransactionsId],
  );

  const paginateTransactions = useCallback(async () => {
    const response =
      (await getTransactions(
        currentPaymentProfileId,
        currentPage.current,
        getApiLimit(viewportHeight),
      )) || [];

    if (!isEmpty(response)) {
      currentPage.current += 1;
    }

    setTransactions((currentTransactions) =>
      !isEmpty(currentTransactions) ? [...currentTransactions, ...response] : response,
    );
  }, [currentPaymentProfileId, getTransactions, viewportHeight]);

  const initiateTransactions = useCallback(async () => {
    currentPage.current = initialPage;
    setTransactions([]);
    return paginateTransactions();
  }, [paginateTransactions]);

  const pollTransactions = useCallback(() => {
    return getTransactions(currentPaymentProfileId, initialPage, pollingLimit, false).then(
      (res) => {
        const response = res || [];
        if (isEmpty(transactions)) {
          setTransactions(response);
          return Promise.resolve(response);
        }

        // Create a lookup table to reduce the Big O of the search
        const transactionPaymentIds: { [key: string]: boolean } = transactions.reduce(
          (acc, curr) => ({ ...acc, [curr.paymentId]: true }),
          {},
        );

        const newTransactions = response.filter(
          ({ paymentId }) => !transactionPaymentIds[paymentId],
        );

        if (!isEmpty(newTransactions)) {
          setTransactions((currentTransactions) => [...newTransactions, ...currentTransactions]);
          return Promise.resolve(newTransactions);
        }

        return Promise.resolve(null);
      },
    );
  }, [getTransactions, currentPaymentProfileId, transactions]);

  const handleFetchTransactions: GetTransactions = useCallback(
    async (actionType) => {
      switch (actionType) {
        case 'INITIAL':
          return initiateTransactions();
        case 'PAGINATION':
          return paginateTransactions();
        case 'POLLING':
          return pollTransactions();
        default:
          return initiateTransactions();
      }
    },
    [initiateTransactions, paginateTransactions, pollTransactions],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initiateTransactions();
  }, [initiateTransactions]);

  const contextValue = useMemo(
    () => ({
      transactions,
      getTransactions: handleFetchTransactions,
      isLoading,
    }),
    [handleFetchTransactions, isLoading, transactions],
  );

  return <transactionContext.Provider value={contextValue}>{children}</transactionContext.Provider>;
}

export default TransactionProvider;
