import { useState, useRef } from 'react';
import request from '@/lib/request';
import API from '@/constants/api';
import { TRANSACTION_STATUS_SUCCEEDED } from '@/constants/transactions';
import { GET_MAX_TRANSACTIONS_FROM_API } from '@/constants/common';
import { PaymentProfileId } from '@/types/paymentProfile';
import { Transaction } from '@/types/transactions';

type GetTransactions = (
  paymentProfileId: PaymentProfileId,
  page: number,
  limit?: number,
  showLoader?: boolean,
) => Promise<Transaction[] | null>;

type UseTransactionApi = {
  getTransactions: GetTransactions;
  isLoading: boolean;
};

function useTransactionApi(): UseTransactionApi {
  const [isLoading, setLoading] = useState(false);

  const getTransactions = useRef<GetTransactions>(
    async (paymentProfileId, page, limit = GET_MAX_TRANSACTIONS_FROM_API, showLoader = true) => {
      if (showLoader) {
        setLoading(true);
      }

      try {
        const apiResponse = await request({
          ...API.GET_TRANSACTIONS,
          data: {
            status: TRANSACTION_STATUS_SUCCEEDED,
            paymentProfileId,
          },
          params: {
            limit,
            offset: page > 0 ? page * limit : 0,
          },
        });

        return (apiResponse?.data || []) as Transaction[];
      } catch (error) {
        if (process.env.NODE_ENV !== 'test') {
          return await Promise.reject(error);
        }
        return null;
      } finally {
        if (showLoader) {
          setLoading(false);
        }
      }
    },
  );

  return { getTransactions: getTransactions.current, isLoading };
}

export default useTransactionApi;
