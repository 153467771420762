import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ReactComponent as PayconiqIcon } from '@/assets/images/icons/transactions/payconiq.svg';
import { ReactComponent as MockProviderIcon } from '@/assets/images/icons/transactions/mock-provider.svg';
import { ReactComponent as EdenredIcon } from '@/assets/images/icons/transactions/edenred.svg';
import { ReactComponent as MonizzeIcon } from '@/assets/images/icons/transactions/monizze.svg';
import { ReactComponent as PluxeeIcon } from '@/assets/images/icons/transactions/pluxee.svg';
import isEmpty from '@/lib/isEmpty';

const transitions = {
  icon1: {
    1: '-50%',
    2: '-86%',
    3: '-125%',
  },
  icon2: {
    2: '-14%',
    3: '-50%',
  },
  icon3: {
    3: '25%',
  },
};

const useStyles = makeStyles(() => ({
  providers: {
    position: 'relative',
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      '&:nth-child(1)': {
        transform: (numberOfIcons) => `translate(${transitions.icon1[numberOfIcons]}, -50%)`,
        zIndex: 5,
      },
      '&:nth-child(2)': {
        transform: (numberOfIcons) => `translate(${transitions.icon2[numberOfIcons]}, -50%)`,
        zIndex: 4,
      },
      '&:nth-child(3)': {
        transform: (numberOfIcons) => `translate(${transitions.icon3[numberOfIcons]}, -50%)`,
        zIndex: 3,
      },
    },
  },
}));

const providerIcons = {
  BEL_EDENRED: <EdenredIcon title="Edenred" key="Edenred" />,
  BEL_MONIZZE: <MonizzeIcon title="Monizze" key="Monizze" />,
  BEL_SODEXO: <PluxeeIcon title="Pluxee" key="Pluxee" />,
  PQI_MOCK_PROVIDER: <MockProviderIcon title="Mock provider" key="MockProvider" />,
};

function TransactionType({ showPayconiq, showVoucher, vasDetails }) {
  let numberOfIcons = showPayconiq ? 1 : 0;
  if (showVoucher) numberOfIcons += (vasDetails || []).length;
  const classes = useStyles(numberOfIcons);

  return (
    <Box className={classes.providers}>
      {showPayconiq && <PayconiqIcon title="Payconiq" />}
      {showVoucher &&
        !isEmpty(vasDetails) &&
        vasDetails.map(({ providerId }) => providerIcons[providerId])}
    </Box>
  );
}

export default TransactionType;
